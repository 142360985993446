<template>
    <div>

   <video id="webcam" autoplay playsinline ></video>
   <b-button
                label=""
                icon-left="camera" 
                @click="TakePhoto('Guardar!', $event)"/>
     
     
   <canvas id="canvas" class=""></canvas>
   
   
</div>
</template>

<script>
import Webcam from 'webcam-easy';
export default {
    components: {
        //   Webcam
},
  props: {

  },
  computed: {
    
  },
  watch: {
       
    },
  mounted() {
   
    const webcamElement = document.getElementById('webcam');
    const canvasElement = document.getElementById('canvas');
    
    this.webcam = new Webcam(webcamElement, 'environment', canvasElement);
    
    this.webcam.start()
   .then(result =>{
      console.log("webcam started", result);
       //webcam.flip();
        //webcam.start();  
   })
   .catch(err => {
       alert(err);
   });
  },
  data() {
    return {
            camera: null,
            deviceId: null,
            devices: [],
            webcam:null,
            
    };
  },
 created() {
            window.SendImage = this.SendImage;
        },
  methods:
  {
   
    TakePhoto(){
        let picture = this.webcam.snap();
         this.SendImage(picture)
        
    },
    SendImage(image){
        this.$emit('TakePhoto', image) 
    },
    
   
  
  }
};
</script>

