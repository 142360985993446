<template>
  <div>
<!--    <div class="columns is-mobile is-centered">
  <div class="column is-half">
   <div class="card">
  <div class="card-image">
    <figure class="image is-4by3">
      <img :src="image" alt="Placeholder image">
    </figure>
  </div>-->
<!--  <div class="card-content">
    
    
    <div class="content">
      <p>
        {{nombre}}
      </p>
    </div>
    
  </div>
</div>

  </div>
</div> -->
<NuevosProductos msg="Nuevos productos"   
      :categorias=categorias 
      :nombre=nombre  
      :ruta4=info.info.Ruta4
      :precio=info.info.Precio
      :descripcion=info.info.Descripcion
      :categoria=info.infoCategoria
      :subcategoria=info.info.Subcategoria
      :checkedSubCategories=info.info.Detalle_Subcategoria
      :producto=producto
      :info=info
      :estado="estado"
      :eliminar="eliminado"
      :existencia="existencia"
      accion="Modificar" 
       />
</div>
</template>

<script>
import NuevosProductos from '@/components/NuevosProductos.vue'
import aws_service from '@/services/aws';
import { mapState } from "vuex";
export default {
  components: {
    NuevosProductos
  },
   mounted() {
   // this.DescargarImagenProducto();
  },
  props: {
    producto: String,
    nombre:String,
    categorias:Array(),
    info:Object(),
    estado:String,
    eliminado:Boolean,
    existencia:String
  },
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      image:"https://bulma.io/images/placeholders/1280x960.png"
    };
  },
  computed: {
    ...mapState(["tienda"])
  },
  methods:
  {
    DescargarImagenProducto(){
        var data= {tienda:this.tienda.Sk,producto:this.producto,imagen:'1'};
            aws_service.obtenerImagenProducto(data).then((response) => {
            console.log(response);
            //var image1= 'data:image/png;base64,' +response.data;
            this.image="https://www.ztec.systems/images/pic01.jpg"
            
            }).catch((error) => {
              console.log(error);
               alert("Imagen no encontrada!");  
            });
        
      }
  }
};
</script>
