<template>
    <div class="columns is-mobile is-centered">
  <div class="column is-half">
    <div class="card">
      </br>
    <p>
      
    <label for="">Color Fondo</label>
    <input type="color" id="html5colorpicker"   v-model="color1" style="width:85%;"/>
    <p>
      </br>
    </p>
    <label for="">Color Tarjeta</label>
    <input type="color" id="html5colorpicker"  v-model="color2" style="width:85%;"/>
    <p>
      </br>
    </p>
    <label for="">Color Texto</label>
    <input type="color" id="html5colorpicker"  v-model="color3" style="width:85%;"/>
    <p>
      </br>
    </p>
    <label for="">Color Iconos</label>
    <input type="color" id="html5colorpicker"  v-model="color4" style="width:85%;"/>
    </p>
  <div class="card-content">
        <b-button @click="GuardarConfiguracion( $event)">Guardar</b-button>
        
    </div>
     
  </div>
</div>
  </div>
</template>

<script>

import aws_service from '@/services/aws';
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["tienda"])
  },
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      file: null,
      nombre:'',
      color1:"#ffffff",
      color2:"#ffFfff",
      color3:"#363636",
      color4:"#2c00a3"
      
      
    };
  },
  mounted() {
   
     this.color1=this.tienda.color1;
     this.color2=this.tienda.color2;
     this.color3=this.tienda.color3;
     this.color4=this.tienda.color4;
     
    
  },
  methods:
  {
    GuardarConfiguracion(e){
        e.preventDefault();
      
        var data={id:this.tienda.Sk,nombre:this.$route.query.tienda,color1:this.color1,color2:this.color2,color3:this.color3, color4:this.color4};
        aws_service.guardarConfiguracion(data).then((response) => {
            console.log(response);
          alert("Cambios guardados");
        }).catch((error) => {
          console.log(error);
           alert("Categoria no guardado!");  
        });
        
      }
    
  }
};
</script>

