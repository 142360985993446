<template>
  <div class="home">
    <NuevasCategorias estado="Activo" eliminar=false accion="Crear" msg="Nueva categoria"/>
    <ul id="categorias" >
      <li v-for="categoria in categorias" :key="categoria.id">
        <div v-if="!categoria.eliminado">
        <Categorias :nombre=categoria.nombre :descripcion=categoria.descripcion :categoria=categoria.id :extencion=categoria.extencion :estado=categoria.estado :eliminado= categoria.eliminado    />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import Categorias from '@/components/Categorias.vue'
import NuevasCategorias from '@/components/NuevasCategorias.vue'
import aws_service from '@/services/aws';
import { mapState } from "vuex";
export default {
  name: 'Home',
   mounted() {
     console.log("dev");
    this.DescargarCategorias();
  },
  computed: {
    ...mapState(["tienda"])
  },
  components: {
    Categorias,
    NuevasCategorias
  },
  data() {
    return {
      categorias:[]
    };
  },
  methods:
  {
    DescargarCategorias(){
      console.log(this.tienda)
      
        var data= {tienda: this.tienda==null?"": this.tienda.Sk};
        aws_service.obtenerCategorias(data).then((response) => {
            console.log(response);
            response.data.forEach(cat=> {
              console.log(cat);
              this.categorias.push({id:cat.Sk.substring(11),nombre:cat.Nombre,descripcion:cat.Descripcion, extencion:cat.Extencion,estado: cat.Estado, eliminado:!cat.Activo})
            });
        }).catch((error) => {
          console.log(error);
           alert("Categoria no guardado!");  
        });  
        
      },
  }
}
</script>
