<template>
  <div class="home">
    <ConfiguraColor msg="Configura color"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ConfiguraColor from '@/components/ConfiguraColor.vue'
import aws_service from '@/services/aws';
import { mapState } from "vuex";
export default {
  name: 'Configuracion',
   mounted() {
     console.log("dev");
  },
  components: {
    ConfiguraColor,
    },
   computed: {
    ...mapState(["tienda"])
  },
  data() {
    return {
      categorias:[]
    };
  },
  methods:
  {
    GuardarColor(){
        var data= {tienda: this.tienda==null?"": this.tienda.Sk};
        aws_service.GuardarColor(data).then((response) => {
            console.log(response);
        }).catch((error) => {
          console.log(error);
           alert("Categoria no guardado!");  
        });  
        
      },
  }
}
</script>
