<template>
    <div class="sidebar-page">
        <section class="sidebar-layout">
             <b-sidebar1
                position="static"
                :fullheight="fullheight"
                
                type="is-light"
                open
            >
                <div class="p-1">
                    <div class="block">
                <!--    <img
                        src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
                        alt="Lightweight UI components for Vue.js based on Bulma"
                    />-->
                    </div>
                    <b-menu class="is-custom-mobile">
                        <b-menu-list label="Menu">
                            <router-link :to="`/?tienda=${tienda.Nombre}`"><b-menu-item icon="home" label="Inicio"></b-menu-item></router-link>
                            <router-link :to="`/configuracion?tienda=${tienda.Nombre}`"><b-menu-item icon="clipboard-list-outline" label="Configuración"></b-menu-item></router-link>
                            <router-link :to="`/categorias?tienda=${tienda.Nombre}`"><b-menu-item icon="clipboard-list-outline" label="Categorias"></b-menu-item></router-link>
                            <router-link :to="`/productos?tienda=${tienda.Nombre}`"><b-menu-item icon="clipboard-list-outline" label="Productos"></b-menu-item></router-link>
                            <router-link :to="`/Solicitudes?tienda=${tienda.Nombre}`"><b-menu-item icon="clipboard-list-outline" label="Solicitudes"></b-menu-item></router-link>
                            
                        </b-menu-list>
                        
                    </b-menu>
                </div>
            </b-sidebar1>

            
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      fullheight: true,
      
    };
  },
  computed: {
    ...mapState(["tienda"])
  }
};
</script>
