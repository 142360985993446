import Axios from 'axios';
import store from '../store'


const RESOURCE_STORE = 'https://dyr7cj5zoc.execute-api.us-west-2.amazonaws.com/api';
const RESOURCE_STORE_FILES = 'https://xvim4wxi0f.execute-api.us-west-2.amazonaws.com/api';
const RESOURCE_SECURITY = 'https://f65p9by2x0.execute-api.us-west-2.amazonaws.com/api';
 //const RESOURCE_STORE = 'http://54.203.155.133:8001';
//const RESOURCE_STORE_FILES= 'http://54.202.2.0:8000';

Axios.interceptors.request.use(function(config) {
    const auth_token = store.getters.token;
 
    if(auth_token!='') {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

export default {

  login(datos)
  {
    
    return Axios.post( `${RESOURCE_SECURITY}/login/`,datos)
  },
  guardarImagenCategoria(tienda,categoria,img,extencion)
  {
    let formData = new FormData();
    formData.append('img',img);
    formData.append('tienda',tienda);
    formData.append('categoria',categoria);
    formData.append('extencion',extencion);
    
    return Axios.post( `${RESOURCE_STORE_FILES}/cargar_categoria`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  obtenerImagenCategoria(data)
  {
    return Axios.post( `${RESOURCE_STORE_FILES}/descargar_categoria`,data)
  },
  guardarCategoria(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_categoria`,data)
  },
  obtenerCategorias(data)
  {
    return Axios.post( `${RESOURCE_STORE}/obtener_categorias`,data)
  },
  obtenerCategoriasProductos(data)
  {
    return Axios.post( `${RESOURCE_STORE}/obtener_categorias_producto`,data)
  },
  obtenerTienda(data) {
    return Axios.post(`${RESOURCE_STORE}/obtener_tienda`, data);
  },
  obtenerSolicitudes(data){
    return Axios.post(`${RESOURCE_STORE}/obtener_solicitudes`, data);
  },
  modificarSolicitud(data){
    return Axios.post(`${RESOURCE_STORE}/modificar_solicitud`, data);
  },
  guardarImagenProducto(tienda,producto,img1,img2,img3,img4,ex1,ex2,ex3,ex4)
  {
    let formData = new FormData();
    formData.append('img1',img1);
    formData.append('img2',img2);
    formData.append('img3',img3);
    formData.append('img4',img4);
    formData.append('tienda',tienda);
    formData.append('producto',producto);
    formData.append('extencion1',ex1);
    formData.append('extencion2',ex2);
    formData.append('extencion3',ex3);
    formData.append('extencion4',ex4);
    return Axios.post( `${RESOURCE_STORE_FILES}/cargar_producto`,
        formData,
        {
        headers: {
             'Content-Type': 'multipart/form-data'
        }
      }
    )

  },
  guardarImagenProductoCamara(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_imagen_producto`,data)
  },
  obtenerImagenProducto(data)
  {
    return Axios.post( `${RESOURCE_STORE_FILES}/descargar_producto`,data)
  },
  guardarProducto(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_producto`,data)
  },
  guardarConfiguracion(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_configuracion`,data)
  },
  guardarCategoriaProducto(data)
  {
    return Axios.post( `${RESOURCE_STORE}/guardar_categoria_producto`,data)
  },
  obtenerProductos(data)
  {
    return Axios.post( `${RESOURCE_STORE}/obtener_productos`,data)
  },

  
};