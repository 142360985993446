<template>
  <div class="home">
    <NuevosProductos msg="Nuevos productos"   :categorias=categorias accion="Crear"  />
    <ul id="productos" >
      <li v-for="producto in productos" :key="producto.id">
        <div v-if="!producto.eliminado">
          <Productos :nombre=producto.nombre :producto=producto.id  :categorias=categorias :info=producto :estado=producto.estado :eliminado= producto.eliminado :existencia=producto.existencia />
        </div>
        <hr>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
import Productos from '@/components/Productos.vue'
import NuevosProductos from '@/components/NuevosProductos.vue'
import aws_service from '@/services/aws';
import { mapState } from "vuex";
export default {
  name: 'Home',
   mounted() {
     console.log("dev");
    this.DescargarCategorias();
    this.DescargarProductos();
  },
  computed: {
    ...mapState(["tienda"])
  },
  components: {
    Productos,
    NuevosProductos
  },
  data() {
    return {
      categorias:[],
      productos:[],
    };
  },
  methods:
  {
    DescargarCategorias(){
        var data= {tienda:this.tienda.Sk};
        aws_service.obtenerCategorias(data).then((response) => {
            console.log(response);
            response.data.forEach(cat=> {
              console.log(cat);
              this.categorias.push({id:cat.Sk.substring(11),nombre:cat.Nombre})
            });
        }).catch((error) => {
          console.log(error);
           alert("Categoria no guardado!");  
        });  
        
      },
      
      DescargarProductos(){
        var data= {tienda:this.tienda.Sk};
        aws_service.obtenerProductos(data).then((response) => {
            console.log(response);
            response.data.forEach(cat=> {
              console.log(cat);
              var eliminado=!cat.Activo;
             if(cat.Activo===undefined)
             {
               eliminado=false;
             }
              this.productos.push({id:cat.Sk.substring(10),nombre:cat.Nombre,info:cat,estado: cat.Estado, eliminado:eliminado, existencia:cat.Existencia})
            });
            console.log(this.productos)
        }).catch((error) => {
          console.log(error);
           alert("Categoria no guardado!");  
        });  
        
      },
  }
}
</script>
