<template>
  <div class="home">
    <b-field label="Año">
            <b-select v-model="anioActual"  @input="CambioFecha($event)" placeholder="Selecciona un año">
                <option
                    v-for="anio in anios"
                    :value="anio"
                    :key="anio">
                    {{ anio }}
                </option>
            </b-select>
    </b-field>
    <b-field   label="Mes">
            <b-select  v-model="mesActual" @input="CambioFecha" placeholder="Selecciona un mes">
                <option
                    v-for="mes in meses"
                    :value="mes.id"
                    :key="mes.id">
                    {{ mes.valor }}
                </option>
            </b-select>
    </b-field>
    <Solicitudes  ref="componenteSolicitudes"/>
  </div>
</template>

<script>
// @ is an alias to /src
//import aws_service from '@/services/aws';
import { mapState } from "vuex";
import Solicitudes from '@/components/Solicitudes.vue'
export default {
  name: 'Home',
   mounted() {
     var difAnios= new Date().getFullYear()-2020
     for(var a = 0; a<difAnios; a++)
     {
       this.anios.push(2021+a)
     }
     this.LlenaMeses()
  },
  computed: {
    ...mapState(["tienda"])
  },
  components: {
    Solicitudes
    
  },
  data() {
    return {
      anios:[],
      meses:[],
      anioActual:null,
      mesActual:null
    };
  },
  methods:
  {
    LlenaMeses()
    {
      this.meses.push({id:"01",valor:"Enero"})
     this.meses.push({id:"02",valor:"Febrero"})
     this.meses.push({id:"03",valor:"Marzo"})
     this.meses.push({id:"04",valor:"Abril"})
     this.meses.push({id:"05",valor:"Mayo"})
     this.meses.push({id:"06",valor:"Junio"})
     this.meses.push({id:"07",valor:"Julio"})
     this.meses.push({id:"08",valor:"Agosto"})
     this.meses.push({id:"09",valor:"Septiembre"})
     this.meses.push({id:"10",valor:"Octubre"})
     this.meses.push({id:"11",valor:"Noviembre"})
     this.meses.push({id:"12",valor:"Diciembre"})
    },
    CambioFecha()
    {
      if(this.anioActual && this.mesActual)
      {
     
        this.$refs.componenteSolicitudes.BuscarSolicitudes(this.anioActual,this.mesActual);
      }
    }
  }
}
</script>
