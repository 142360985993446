<template>
    <div>
    <div class="columns is-mobile is-centered">
  <div class="column is-fullheight">
    <div class="card">
        
  <div class="card-content">
    
    <div class="card-content">
    
    
    <div  v-if="producto" class="content">
      <p>
        {{nombre}}
      </p>
    </div>
     
  </div>
   
    <div class="content">
     
               
        
              
    <b-field class="file is-primary" :class="{'has-name': !!file1}">
         <b-button
                label=""
                icon-left="camera" 
                @click="CameraActivation(1)" 
                />
        <b-upload v-model="file1" class="file-label" accept="image/*" >
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Subir imagen 1</span>
            </span>
            <span class="file-name" v-if="file1">
                {{ file1.name }}
            </span>
        </b-upload>
    </b-field>

    <b-field class="file is-primary" :class="{'has-name': !!file2}">
        <b-button
                label=""
                icon-left="camera" 
                @click="CameraActivation(2)" 
                @TakePhoto="TakePhoto"/>
        <b-upload v-model="file2" class="file-label" accept="image/*" >
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Subir imagen 2</span>
            </span>
            <span class="file-name" v-if="file2">
                {{ file2.name }}
            </span>
        </b-upload>
    </b-field>
  
    <b-field class="file is-primary" :class="{'has-name': !!file3}">
        <b-button
                label=""
                icon-left="camera" 
                @click="CameraActivation(3)" 
                @TakePhoto="TakePhoto"/>
        <b-upload v-model="file3" class="file-label" accept="image/*" >
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Subir imagen 3</span>
            </span>
            <span class="file-name" v-if="file3">
                {{ file3.name }}
            </span>
        </b-upload>
    </b-field>
    
    <b-field class="file is-primary" :class="{'has-name': !!file4}">
        <b-button
                label=""
                icon-left="camera" 
                @click="CameraActivation(4)" 
                @TakePhoto="TakePhoto"/>
        <b-upload v-model="file4" class="file-label" accept="image/*" >
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Subir imagen video</span>
            </span>
            <span class="file-name" v-if="file4">
                {{ file4.name }}
            </span>
        </b-upload>
    </b-field>
    
    <b-field>
            <b-input v-model="ruta4" placeholder="Ruta video"
                icon="youtube">
            </b-input>
        </b-field>
        

      <b-field>
            <b-input v-model="nombre" placeholder="Nombre del producto"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
        
        <b-field>
            <b-input v-model="precio" placeholder="Precio del producto"
                icon-pack="fas"
                icon="dollar-sign">
            </b-input>
        </b-field>
        
        <b-field>
            <b-taginput
                v-model="checkedCategories"
                :data="categorias"
                autocomplete
                :allow-new="allowNew"
                :open-on-focus="openOnFocus"
                field="nombre"
                icon="label"
                placeholder="Agrega una categoria"
                @typing="getFilteredTags">
            </b-taginput>
        </b-field>
        
        <b-field label="Descripcion"
            :label-position="labelPosition">
            <b-input  v-model="descripcion"   id="descripcion" ref="ref_descripcion"  type="textarea"></b-input>
        </b-field>
        <b-field>
            <b-input v-model="subcategoria" placeholder="Subcategoria"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
      
        <b-field>
            <b-taginput
                v-model="checkedSubCategories"
                :data="subcategorias"
                autocomplete
                :allow-new="allowNewSub"
                :open-on-focus="openOnFocus"
                field="nombre"
                icon="label"
                placeholder="Agrega una subcategoria"
        >
            </b-taginput>
        </b-field>
          <b-field>
            <b-input v-model="existencia" placeholder="Existencia del producto"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
        
        <div v-if="accion=='Modificar'">
          <b-field>
            <b-switch v-model="estado"
                true-value="Activo"
                false-value="Inactivo">
                {{ estado }}
            </b-switch>
        </b-field>
        <b-checkbox v-model="eliminar">
                Eliminar
            </b-checkbox>
        </div>
        <div v-if="producto">
            <b-button @click="ModificarProducto('Guardar!', $event)">Modificar</b-button>    
        </div>
        <div v-else>
            <b-button @click="GuardarProducto('Guardar!', $event)">Agregar</b-button>    
        </div>
        
        
    </div>
     
  </div>
</div>
  </div>
   
</div>
<b-modal v-model="isImageModalActive">
                <Camara @TakePhoto="TakePhoto"/>
        </b-modal>
<b-modal v-model="isPreviewModalActive">
                <img v-bind:src="preview" />
                
                <b-button
                label=""
                icon-left="undo" 
                @click="RotateBase64Image()"/>
                <b-button
                label=""
                icon-left="check" 
                @click="GetPhoto()"/>
</b-modal>
<canvas id="canvasPreview" class="canvasPreview"></canvas>
</div>
</template>

<script>
import aws_service from '@/services/aws';
import { uuid } from 'vue-uuid';
import { mapState } from "vuex";
import Camara from '@/components/Camara'
export default {
    components: {
          
           Camara
},
  props: {
    categorias: Array(),
    nombre:String,
    ruta4:String,
      precio:String,
      descripcion:String,
      categoria:String,
      subcategoria:String,
      checkedCategories:Array(),
      checkedSubCategories:Array(),
      producto:String,
      info:Object(),
       estado: String,
      eliminar:Boolean,
      existencia:String,
      accion:String
     
      
      
      
  },
  computed: {
    ...mapState(["tienda"]),
  
  },
  watch: {
       
    },
  mounted() {
    if(this.producto!= null)
    {
        var data= {tienda:this.tienda.Sk, producto:this.producto};
        aws_service.obtenerCategoriasProductos(data).then((response) => {
            console.log(response);
            this.checkedCategories=[];
            response.data.forEach(function (cat) {
              console.log(cat);
              console.log(this.categorias);
              
              this.checkedCategories.push({id:cat.Pk.substring(54),nombre:this.categorias.filter(x=>x.id==cat.Pk.substring(54))[0].nombre})
              console.log(this.checkedCategories)
            },this);
        }).catch((error) => {
          console.log(error);
           alert("Categoria no encontrada!");  
        });  
        
    }
 
  },
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      file1: null,
      file2: null,
      file3: null,
      file4: null,
      labelPosition:'on-border',
      isSelectOnly: false,
      tags: [],
      allowNew: false,
      allowNewSub: true,
      openOnFocus: true,
      img: null,
      camaraActual:0,
      isImageModalActive:false,
      isPreviewModalActive:false,
      fotosCamara:[],
      preview:null,
      original:false,
    };
  },
  methods:
  {
    RotateBase64Image() {
        this.original=false;
        var base64data= this.preview;
        var canvas = document.getElementById("canvasPreview");
        var ctx = canvas.getContext("2d");
        
        var image = new Image();
        image.src = base64data;
        
        let vm = this;
        
        image.onload = function() {
            canvas.width =  image.height;
        canvas.height = image.width;
            ctx.rotate(-90 * Math.PI / 180);
            ctx.translate(-image.width, 0);
            //ctx.translate(image.width, image.height);
            
            //ctx.rotate(90 * Math.PI / 180);
            ctx.drawImage(image, 0, 0); 
            vm.preview= canvas.toDataURL();
        };
      

},
     getFilteredTags(text) {
                this.filteredTags = this.categorias.filter((option) => {
                    return option.user.nombre
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
    CameraActivation(op){
        
            this.camaraActual=op;
              this.isImageModalActive = true  
            },
            GetPhoto(){
              this.fotosCamara[this.camaraActual]=this.preview
              this.isPreviewModalActive = false
            },
    TakePhoto(picture){

        this.isImageModalActive = false
        console.log(picture);
        
        this.preview= picture
        this.isPreviewModalActive = true
        this.original=true;
        
        
        var base64data= this.preview;
        var canvas = document.getElementById("canvasPreview");
        var ctx = canvas.getContext("2d");
        
        var image = new Image();
        image.src = base64data;
        
        let vm = this;
        
        image.onload = function() {
            ctx.drawImage(image, 0, 0); 
          //  vm.preview= canvas.toDataURL();
        };
        
      
    },
    GuardarProducto(msg,e){
        e.preventDefault();
        var nuevoId=uuid.v1();
        console.log(this.descripcion)
        console.log(this.$refs.ref_descripcion)
        console.log(document.getElementById("descripcion"))
        console.log(this.checkedCategories)
        var re = /(?:\.([^.]+))?$/;
        var extencion1 = re.exec(this.file1 != null?this.file1.name : "")[1]; 
        var extencion2 = re.exec(this.file2 != null?this.file2.name : "")[1];
        var extencion3 = re.exec(this.file3 != null?this.file3.name : "")[1];
        var extencion4 = re.exec(this.file4 != null?this.file4.name : "")[1];
           
           
        aws_service.guardarImagenProducto(this.tienda.Sk,
        nuevoId,this.file1,this.file2,this.file3,this.file4,extencion1,extencion2,extencion3,extencion4).then((response) => {
             console.log(response);
             var num_productos= "0"
             if(this.existencia)
             {
                 num_productos=this.existencia
             }
            var data= {tienda:this.tienda.Sk,producto:nuevoId,
                        nombre:this.nombre,descripcion:this.descripcion,precio:this.precio,
                        subcategoria:this.subcategoria,detalle_sub:this.checkedSubCategories,
                        ruta4:this.ruta4,extencion1:extencion1, extencion2:extencion2, extencion3:extencion3,extencion4:extencion4,estado:'Activo', activo: true,existencia:num_productos
            }
            
            
               for(var i =1;i<=4;i++)   
        if(this.fotosCamara[i])
        {
            if(i==1){extencion1='png'}
                if(i==2){extencion2='png'}
                if(i==3){extencion3='png'}
                if(i==4){extencion4='png'}
            var dataF={tienda:this.tienda.Sk,producto:nuevoId, imagen: this.fotosCamara[i],num_producto:"0"+i}
            aws_service.guardarImagenProductoCamara(dataF).then((response) => {
            }).catch((error) => {
                  console.log(error);
                   alert("Imagen no guardada!");  
                });
        }
            
            
            
            
                aws_service.guardarProducto(data).then((response) => {
                    console.log(response);
                    var contador= this.checkedCategories.length;
                    var terminadas=0;
                    this.checkedCategories.forEach(category=>
                    {
                        var data= {tienda:this.tienda.Sk,producto:nuevoId,categoria:category.id,estado:'Activo', activo: true}
                        aws_service.guardarCategoriaProducto(data).then((response) => {
                            console.log(response);
                            terminadas++;
                            if(terminadas==contador)
                            {alert("Producto guardado!");
                                location.reload();
                            }
                        }).catch((error) => {
                          console.log(error);
                           alert("Categoria del Producto no guardado!");  
                        });
                    });
                    //alert("Producto guardado!");
                    //location.reload();
                }).catch((error) => {
                  console.log(error);
                   alert("Producto no guardado!");  
                });
              
        }).catch((error) => {
          console.log(error);
           alert("Producto no guardado!");  
        });
        
      },
    
    ModificarProducto(msg,e){
        e.preventDefault();
        var re = /(?:\.([^.]+))?$/;
        var extencion1 = re.exec(this.file1 != null?this.file1.name : "")[1]; 
        var extencion2 = re.exec(this.file2 != null?this.file2.name : "")[1];
        var extencion3 = re.exec(this.file3 != null?this.file3.name : "")[1];
        var extencion4 = re.exec(this.file4 != null?this.file4.name : "")[1];
       
           
        aws_service.guardarImagenProducto(this.tienda.Sk,
        this.producto,this.file1,this.file2,this.file3,this.file4,extencion1?extencion1:this.info.info.Extencion1,extencion2?extencion2:this.info.info.Extencion2,extencion3?extencion3 : this.info.info.Extencion3,extencion4?extencion4:this.info.info.Extencion4).then((response) => {
             console.log(response);
             
             
              for(var i =1;i<=4;i++)   
        if(this.fotosCamara[i])
        {
                if(i==1){extencion1='png'}
                if(i==2){extencion2='png'}
                if(i==3){extencion3='png'}
                if(i==4){extencion4='png'}
            var dataF={tienda:this.tienda.Sk,producto:this.producto, imagen: this.fotosCamara[i],num_producto:"0"+i}
            aws_service.guardarImagenProductoCamara(dataF).then((response) => {
            }).catch((error) => {
                  console.log(error);
                   alert("Imagen no guardada!");  
                });
        }
           var num_productos= "0"
             if(this.existencia)
             {
                 num_productos=this.existencia
             }
             
             
             
            var data= {
                        tienda:this.tienda.Sk,producto:this.producto,
                        nombre:this.nombre,descripcion:this.descripcion,precio:this.precio,
                        subcategoria:this.subcategoria,detalle_sub:this.checkedSubCategories,
                        ruta4:this.ruta4,extencion1:extencion1?extencion1:this.info.info.Extencion1,extencion2:extencion2?extencion2:this.info.info.Extencion2,
                        extencion3:extencion3?extencion3: this.info.info.Extencion3,extencion4:extencion4?extencion4:this.info.info.Extencion4,estado:this.estado, activo: !this.eliminar,existencia:num_productos
                        }
                aws_service.guardarProducto(data).then((response) => {
                    console.log(response);
                    var contador= this.checkedCategories.length;
                    var terminadas=0;
                    this.checkedCategories.forEach(category=>
                    {
                        var data= {tienda:this.tienda.Sk,producto:this.producto,categoria:category.id,estado:this.estado, activo: !this.eliminar}
                        aws_service.guardarCategoriaProducto(data).then((response) => {
                            console.log(response);
                            terminadas++;
                            if(terminadas==contador)
                            {alert("Producto guardado!");
                                location.reload();
                            }
                        }).catch((error) => {
                          console.log(error);
                           alert("Categoria del Producto no guardado!");  
                        });
                    });
                    //alert("Producto guardado!");
                    //location.reload();
                }).catch((error) => {
                  console.log(error);
                   alert("Producto no guardado!");  
                });
              
        }).catch((error) => {
          console.log(error);
           alert("Producto no guardado!");  
        });
        
      }
    
  }
};
</script>


<style>
.canvasPreview{
  position: absolute;
  left: -500px;
  top: -500px;
}
</style>

