import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Categorias from '../views/Categorias.vue'
import Productos from '../views/Productos.vue'
import Solicitudes from '../views/Solicitudes.vue'
import Configuracion from '../views/Configuracion.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/configuracion',
    name: 'Configuracion',
    component: Configuracion
  },
  {
    path: '/categorias',
    name: 'Categorias',
    component: Categorias
  },{
    path: '/productos',
    name: 'Productos',
    component: Productos
  },
  {
    path: '/solicitudes',
    name: 'Solicitudes',
    component: Solicitudes
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
