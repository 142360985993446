<template>
  <div>
    <div class="columns is-mobile is-centered">
  <div class="column is-half">
   <div class="card" @click="Editar">
  <div class="card-image">
    <figure class="image is-4by3">
      <img :src="image" alt="Placeholder image">
    </figure>
  </div>
  <div class="card-content">
    <div class="content">
      <p>
        {{nombre}}
      </p>
    </div>
  </div>
</div>

  </div>
  
</div>
<NuevasCategorias v-if="editando" :estado="estado" :eliminar="eliminado" accion="Modificar" msg="Modifica categoria" :idCategoria="categoria" :nombre="nombre" :descripcion="descripcion" :extencion="extencion"/>
</div>
</template>

<script>
import NuevasCategorias from '@/components/NuevasCategorias.vue'
//import aws_service from '@/services/aws';
import { mapState } from "vuex";
export default {
  components: {
    NuevasCategorias
  },
   mounted() {
    this.DescargarImagenCategoria();
   
  },
  props: {
    categoria: String,
    nombre:String,
    extencion:String,
    descripcion:String,
    estado:String,
    eliminado:Boolean
  },
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      image:"https://bulma.io/images/placeholders/1280x960.png",
      editando:false,
    };
  },
  computed: {
    ...mapState(["tienda"])
  },
  methods:
  {
    DescargarImagenCategoria(){
      this.image='https://tiendas-ztec.s3-us-west-2.amazonaws.com/'+this.tienda.Sk+'/categorias/'+this.categoria+'.'+this.extencion;
/*        var data= {tienda:'69a32dcf-c7b5-48d5-818f-ea480bb909a2',categoria:this.categoria};
            aws_service.obtenerImagenCategoria(data).then((response) => {
            console.log(response);
            this.image= 'data:image/png;base64,' +response.data;
            
            }).catch((error) => {
              console.log(error);
               alert("Imagen no encontrada!");  
            });
      */  
      },
      Editar()
      {
        this.editando=!this.editando;
      }
  }
};
</script>
