<template>
    <section>

    <div v-if="cargado">
        <b-table
            :data="isEmpty ? [] : data"
            ref="table"
            paginated
            per-page="50"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="Sk"
           :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">

            
            <b-table-column field="Pk" label="" width="40"  v-slot="props">
                
             <b-button @click= "Editar(props.row)"    icon-right="pencil" />    
            </b-table-column>
            <b-table-column field="Pk" label="Clave" width="40"  v-slot="props">
                {{ props.row.clave }}
            </b-table-column>
            
            <b-table-column field="Pk" label="Vendedor" width="40"  v-slot="props">
                {{ props.row.telefono }}
            </b-table-column>

            <b-table-column field="user.first_name" label="Nombre" sortable v-slot="props">
                <template >
                    {{ props.row.nombre_cliente }}
                </template>
            </b-table-column>
            
            <b-table-column field="user.first_name" label="Teléfono" sortable v-slot="props">
                <template >
                    {{ props.row.telefono_cliente }}
                </template>
            </b-table-column>
            <b-table-column field="user.first_name" label="Correo" sortable v-slot="props">
                <template >
                    {{ props.row.correo_cliente }}
                </template>
            </b-table-column>

            <b-table-column field="user.last_name" label="Fecha" sortable v-slot="props">
                {{ props.row.fecha }} {{ props.row.hora }}
            </b-table-column>

            <b-table-column field="date" label="Estado" sortable centered v-slot="props">
                <span class="tag is-success">
                    {{ props.row.Estado }}
                </span>
            </b-table-column>

            <b-table-column label="Solicitado" v-slot="props">
                <span>
                    {{ props.row.total_solicitado }}
                </span>
            </b-table-column>
            
             <b-table-column label="Comprado" v-slot="props">
                <span>
                    {{ props.row.total_comprado }}
                </span>
            </b-table-column>

            <template #detail="props">
                <article class="media">
                  
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <ul id="example-1">
                                  <li v-for="item in props.row.solicitud" :key="item.clave" >
                                    {{ item.cantidad }} {{ item.producto }} {{ item.precioUnitario }} c/u
                                     <ul id="example-1">
                                          <li v-for="subcategoria in item.subcategorias">
                                            subcategoria
                                          </li>
                                    </ul>
                                  </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>
        </div>
 <b-modal ref="editor"
            v-model="isComponentModalActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Example Modal"
            aria-modal>
            
            
            
             <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Actilizar</p>
                        <button
                            type="button"
                            class="delete"
                            @click="CerrarModal"/>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Nombre">
                            <b-input
                                v-model="nombre"
                                placeholder="Nombre"
                                >
                            </b-input>
                        </b-field>

                        <b-field label="Teléfono">
                            <b-input
                                v-model="telefono"
                                placeholder="Teléfono"
                                >
                            </b-input>
                        </b-field>

                        <b-field label="Correo">
                            <b-input
                                v-model="correo"
                                placeholder="Correo"
                                >
                            </b-input>
                        </b-field>
                        
                        <b-field label="Estado">
                            <b-select v-model="estado" placeholder="Estado">
                                <option value="Solicitado">Solicitado</option>
                                <option value="Vendido">Vendido</option>
                                <option value="Cancelado">Cancelado</option>
                            </b-select>
                        </b-field>
                            
                        <b-field label="Compra">
                            <b-input
                                v-model="compra"
                                placeholder="Compra"
                                >
                            </b-input>
                        </b-field>

    
    
                         <b-field label="Observación">
                            <b-input
                                v-model="observacion"
                                placeholder="Observación"
                                >
                            </b-input>
                        </b-field>

                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Close"
                            @click="CerrarModal" />
                        <b-button
                            label="Modificar"
                            type="is-primary"
                             @click="Modificar"/>
                    </footer>
                </div>
            </form>
            

        </b-modal>
    </section>
</template>

<script>
    
import aws_service from '@/services/aws';
    export default {
        components: {
        
        },
        data() {
            return {
                data : [],
               nombre:"",
               telefono:"",
               correo:"",
               estado:"",
               compra:0,
               Pk:"",
               Sk:"",
               observacion:"",
                defaultOpenedDetails: [1],
                showDetailIcon: true,
                useTransition: false,
               isComponentModalActive: false,
               cargado:true,
            }
        },
        computed: {
          
        },
        methods:
        {
            Editar(renglon)
            {
           // this.$refs.editor.editor.actualizarDatos(renglon)
               this.nombre= renglon.nombre_cliente;
               this.telefono= renglon.telefono_cliente;
               this.correo= renglon.correo_cliente;
               this.estado=renglon.Estado;
               this.compra=renglon.total_comprado;
               this.Pk=renglon.Pk;
               this.Sk=renglon.Sk;
               this.observacion=renglon.observacion;
              this.isComponentModalActive = true
            },
            Modificar()
            {
                var data= {
                       Pk:this.Pk, Sk:this.Sk,nombre_cliente:!this.nombre?"x":this.nombre,
                       telefono_cliente:!this.telefono?"x":this.telefono,correo_cliente:!this.correo?"x":this.correo,
                       Estado:this.estado,total_comprado:!this.compra?"0":this.compra,observacion:!this.observacion?"x":this.observacion
                        }
                aws_service.modificarSolicitud(data).then((response) => {
                    console.log(response);
                    alert("Se actualizaron los datos")
                    
                    var actualizarDatos= function(pk,sk,data){
                        return function(dato)
                        {
                              if(dato.Pk == pk && dato.Sk == sk){
                                dato.nombre_cliente = data.nombre_cliente;
                                dato.telefono_cliente = data.telefono_cliente;
                                dato.correo_cliente = data.correo_cliente;
                                dato.Estado = data.Estado;
                                dato.total_comprado= data.total_comprado;
                                dato.observacion= data.observacion;
                              }
                            return dato;
                        }
                    };
                    this.data.map(actualizarDatos(this.Pk,this.Sk,data));
                    
                    console.log(this.data)
                    this.cargado=false;
                    this.cargado = true;
                    /*this.data.map(function(dato){
                      if(dato.Pk == this.Pk && dato.Sk == this.Sk){
                        dato.nombre_cliente = this.nombre;
                        dato.telefono_cliente = this.telefono;
                        dato.correo_cliente = this.correo;
                        dato.Estado = this.estado;
                        dato.total_comprado= this.compra;
                        dat.observacion= this.observacion;
                      }
                      
                      return dato;
                    });*/

                }).catch((error) => {
                  console.log(error);
                   alert("Solicitudes no encontradas!");  
                });
                 this.isComponentModalActive = false    
            },
            CerrarModal()
            {
                this.isComponentModalActive = false
            },
            BuscarSolicitudes(anio,mes)
            {
                var data= {
                       periodo:anio+"-"+mes+"#"
                        }
                aws_service.obtenerSolicitudes(data).then((response) => {
                    console.log(response);
                   this.data=response.data;
                 
                }).catch((error) => {
                  console.log(error);
                   alert("Solicitudes no encontradas!");  
                });
              
            }
        }
        
    }
</script>

