<template>
  <div id="app">
    <div class="columns">
      <div class="column is-narrow">
        <Menu msg="Welcome to Your Vue.js App"/>
      </div>
      <div class="column">
        <div >
          <router-view/>
        </div>
      </div>
    </div>
     
    
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import aws_service from "@/services/aws"
import {  mapMutations } from "vuex"
export default {
  name: 'Home',
  components: {
    Menu
  },
  mounted() {
   // this.ObtenTienda();
  },
  methods: {
    ...mapMutations(["asignar_tienda"]),
    ObtenTienda(){
    
     var data = { nombre: this.$route.query.tienda };
      aws_service
        .obtenerTienda(data)
        .then(response => {
          console.log(response);
          var tienda=response.data
          tienda.Sk=tienda.Sk.substring(tienda.Sk.indexOf('#')+1)
          console.log(tienda)
          this.asignar_tienda(tienda)
          this.telefono= tienda.Telefono;
        })
        .catch(error => {
          console.log(error);
          alert("Tienda no encontrada!");
        });
     
      }
    
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
