<template>
    <div class="columns is-mobile is-centered">
  <div class="column is-half">
    <div class="card">
    <b-field class="file is-primary" :class="{'has-name': !!file}">
        <b-upload v-model="file" class="file-label" accept="image/*" >
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">Subir imagen</span>
            </span>
            <span class="file-name" v-if="file">
                {{ file.name }}
            </span>
        </b-upload>
    </b-field>
  <div class="card-content">
    <div class="content">
      <b-field>
            <b-input v-model="nombre" placeholder="Categoria"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
      <b-field>
            <b-input v-model="descripcion" placeholder="Descripcion"
                icon-pack="fas"
                icon="list-alt">
            </b-input>
        </b-field>
        <div v-if="accion=='Modificar'">
          <b-field>
            <b-switch v-model="estado"
                true-value="Activo"
                false-value="Inactivo">
                {{ estado }}
            </b-switch>
        </b-field>
        <b-checkbox v-model="eliminar">
                Eliminar
            </b-checkbox>
        </div>
        <b-button @click="GuardarCategoria('Guardar!', $event)">{{msg}}</b-button>
        
    </div>
     
  </div>
</div>
  </div>
</div>
</template>

<script>
import aws_service from '@/services/aws';
import { uuid } from 'vue-uuid';
import { mapState } from "vuex";
export default {
  props:
  {
    idCategoria:String,
    msg:String,
    accion:String,
    nombre:String,
    descripcion:String,
    extencion:String,
    estado: String,
      eliminar:Boolean,
  },
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      file: null,
      
      
      
    };
  },
  computed: {
    ...mapState(["tienda"])
  },
  methods:
  {
    GuardarCategoria(msg,e){
        e.preventDefault();
        var re = /(?:\.([^.]+))?$/;
        if(!this.idCategoria)
        {
          var nuevoId=uuid.v1();
          //var archivo_img = this.$refs.file_img.files[0];
           
            var extencion = re.exec(this.file.name)[1]; 
           
          aws_service.guardarImagenCategoria(this.tienda.Sk,nuevoId,this.file,extencion).then((response) => {
              console.log(response);
              var data= {tienda:this.tienda.Sk,categoria:nuevoId,nombre:this.nombre,extencion:extencion, descripcion:this.descripcion,estado:"Inactivo", activo: true}
              aws_service.guardarCategoria(data).then((response) => {
                  console.log(response);
                  alert("Categoria guardada!");
                  location.reload();
              }).catch((error) => {
                console.log(error);
                 alert("Categoria no guardado!");  
              });  
          }).catch((error) => {
            console.log(error);
             alert("Categoria no guardado!");  
          });
        }
        else{
          var extencion1=this.extencion
          if(this.file)
          {
            
             extencion1 = re.exec(this.file.name)[1]; 
            alert(extencion1);
            aws_service.guardarImagenCategoria(this.tienda.Sk,this.idCategoria,this.file,extencion1).then((response) => {
                console.log(response);
            }).catch((error) => {
              console.log(error);
               alert("Categoria no guardado!");  
            });
          }
          var data= {tienda:this.tienda.Sk,categoria:this.idCategoria,nombre:this.nombre,extencion:extencion1, descripcion:this.descripcion,estado:this.estado, activo: !this.eliminar}
              aws_service.guardarCategoria(data).then((response) => {
                  console.log(response);
                  alert("Categoria guardada!");
                  location.reload();
              }).catch((error) => {
                console.log(error);
                 alert("Categoria no guardado!");  
              });  
        
        }
        
      }
    
  }
};
</script>

